import { lazy } from 'react';

const Festival = lazy(() => import('../../views/Festival'));

const FestivalRoutes = [
    {
        path: '/festival',
        element: <Festival />
    }
];

export default FestivalRoutes;
