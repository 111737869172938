import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getInstitute = createAsyncThunk('instituteSlice/getInstitute', async () => {
    try {
        let response = await axios.get('/institute', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getInstituteServer = createAsyncThunk('instituteSlice/getInstituteServer', async (id) => {
    try {
        // showLoader(true);
        const query = queryString.stringify(id);
        let response = await axios.get('/institute-server' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createInstitute = createAsyncThunk('instituteSlice/createInstitute', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/institute', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Institute added successfully.');
        dispatch(getInstitute());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getInstituteById = createAsyncThunk('instituteSlice/getInstituteById', async (id) => {
    try {
        let response = await axios.get('/institute/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBulkInstitute = createAsyncThunk('instituteSlice/createBulkInstitute', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/institute-bulk/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        // dispatch(getInstituteServer({ serverId: data?.id }));
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateInstitute = createAsyncThunk('instituteSlice/updateInstitute', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/institute/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Institute updated successfully.');
        dispatch(getInstitute());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteInstitute = createAsyncThunk('instituteSlice/deleteInstitute', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/institute/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Institute deleted successfully.');
        dispatch(getInstitute());
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const mockLoginSK = createAsyncThunk('instituteSlice/mockLoginSK', async (data, { dispatch }) => {
    try {
        let response = await axios.get('/mock-login/' + data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        // toast.success(response?.data?.message || 'Institute Login successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('instituteSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/institute/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getInstitute());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const instituteSlice = createSlice({
    name: 'instituteSlice',
    initialState: {
        data: [],
        serverData: [],
        selectedInstitute: {}
    },
    reducers: {
        handleSelectedInstitute: (state, action) => {
            state.selectedInstitute = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getInstitute.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getInstituteServer.fulfilled, (state, action) => {
            state.serverData = action?.payload;
            return;
        });

        builder.addCase(getInstituteById.fulfilled, (state, action) => {
            state.selectedInstitute = action.payload;
            return;
        });
    }
});

export const { handleSelectedInstitute } = instituteSlice.actions;

export default instituteSlice.reducer;
