// ** Icons Import
import { Home, Circle } from 'react-feather';

export default [
    {
        id: 'image-template',
        title: 'Image Template',
        icon: <Home size={20} />,
        navLink: '/image-template'
    }
];
