import { lazy } from 'react';

const Institute = lazy(() => import('../../views/Institute'));
const InstituteForm = lazy(() => import('../../views/Institute/components/Form'));

const InstituteRoutes = [
    {
        path: '/institute',
        element: <Institute />
    },
    {
        path: '/institute/add',
        element: <InstituteForm />
    },
    {
        path: '/institute/edit/:id',
        element: <InstituteForm />
    },
    {
        path: '/institute/login/:id',
        element: <InstituteForm />
    }
];

export default InstituteRoutes;
