// ** Icons Import
import { Icon } from '@iconify/react';

export default [
    {
        id: 'festival',
        title: 'Festival',
        icon: <Icon icon="material-symbols:festival" width="30" height="30" />,
        navLink: '/festival'
    }
];
