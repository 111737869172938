import { useEffect, useState } from 'react';

// ** Reactstrap Imports
import { Modal, Input, Label, Button, ModalHeader, ModalBody, Form, FormFeedback, Row, Col, ModalFooter } from 'reactstrap';

// ** Third Party Components
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react/dist/iconify.js';
import Select from 'react-select';
import { selectThemeColors } from '@utils';

// ** Axios Api
import { createLabel, updateLabel } from '../store';
import { useDispatch } from 'react-redux';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { handleServerError } from '../../../../service/common';
const LabelModal = ({ open, handleModal, labelData }) => {
    // dispatch object
    const dispatch = useDispatch();

    // stated
    const [buttonStatus, setButtonStatus] = useState(false);

    // Yup validations
    const validationScheme = yup.object().shape({
        name: yup.string().required('Name is required.').nullable(),
        type: yup
            .object()
            .shape({
                value: yup.string().required('Type is required.'),
                label: yup.string()
            })
            .required('Type is required.')
            .nullable(),
        description: yup.string().required('Description is required.').nullable()
    });

    // Hook Form
    const {
        register,
        reset,
        handleSubmit,
        setError,
        control,
        formState: { isDirty, errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    const typeOptions = [
        { value: '1', label: 'Web' },
        { value: '2', label: 'Mobile' }
    ];
    const onSubmit = async (data) => {
        setButtonStatus(true);
        const groupData = {
            name: data?.name,
            type: data?.type?.value,
            description: data?.description
        };

        if (!isDirty) {
            toast.warning('No changes occurred');
            setButtonStatus(false);
            return;
        }

        if (labelData?.id) {
            // Update label
            await dispatch(updateLabel({ id: labelData?.id, data: groupData }))
                .unwrap()
                .then((res) => {
                    handleModal();
                    setButtonStatus(false);
                })
                .catch((err) => {
                    setButtonStatus(false);
                });
        } else {
            // Create new label
            await dispatch(createLabel(groupData))
                .unwrap()
                .then((res) => {
                    handleModal();
                    setButtonStatus(false);
                })
                .catch((err) => {
                    if (err?.response?.data) {
                        handleServerError(err, setError);
                    }
                    setButtonStatus(false);
                });
        }
    };

    // register form fields
    const { ref: refName, ...restName } = register('name');
    const { ref: refDescription, ...restDescription } = register('description');

    useEffect(() => {
        const selectedType = typeOptions.find((option) => option.value === labelData?.type);
        reset({
            name: labelData?.name || null,
            type: selectedType || null,

            description: labelData?.description || null
        });
    }, [labelData]);

    return (
        <Modal isOpen={open} size="lg" className="modal-dialog-centered" contentClassName=" d-flex pt-0">
            <ModalHeader toggle={handleModal} tag="div">
                <div className="icon d-flex justify-content-center" style={{ alignItems: 'center' }}>
                    <Icon className="icon-color" icon="ion:language" width={26} height={26} style={{ paddingRight: '6px' }} />
                    <h5 className="modal-title" style={{ margin: 0 }}>
                        {labelData?.id ? 'Edit Label' : 'Add Label'}
                    </h5>
                </div>
            </ModalHeader>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <Row>
                        <Col md="12" sm="12" className="mb-1">
                            <Label>
                                Name<span className="label-css">*</span>
                            </Label>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Name"
                                invalid={errors?.name && true}
                                {...restName}
                                innerRef={refName}
                            />
                            {errors && errors?.name && <FormFeedback>{errors?.name?.message}</FormFeedback>}
                        </Col>

                        <Col md="12" sm="12" className="mb-1">
                            <Label>
                                Type<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="type"
                                control={control}
                                name="type"
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="select"
                                        placeholder={'Select Type'}
                                        theme={selectThemeColors}
                                        options={typeOptions}
                                        className={classNames('react-select', {
                                            'is-invalid':
                                                errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message
                                        })}
                                        {...field}
                                    />
                                )}
                            />
                            {errors && errors?.type && (
                                <FormFeedback>
                                    {errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message}
                                </FormFeedback>
                            )}
                        </Col>

                        <Col md="12" sm="12" className="mb-1">
                            <Label>
                                Description<span className="label-css">*</span>
                            </Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                rows="3"
                                placeholder="Description"
                                invalid={errors.description && true}
                                {...restDescription}
                                innerRef={refDescription}
                            />

                            {errors && errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={buttonStatus} color="primary" type="submit">
                        Submit
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={() => {
                            reset();
                            setButtonStatus(false);
                        }}
                    >
                        Reset
                    </Button>
                    <Button color="secondary" onClick={handleModal} outline>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default LabelModal;
