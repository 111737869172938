import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getBanner = createAsyncThunk('bannerSlice/getBanner', async () => {
    try {
        let response = await axios.get('/banner', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBanner = createAsyncThunk('bannerSlice/createBanner', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/banner', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Banner added successfully.');
        dispatch(getBanner());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBannerById = createAsyncThunk('bannerSlice/getBannerById', async (id) => {
    try {
        let response = await axios.get('/banner/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBanner = createAsyncThunk('bannerSlice/updateBanner', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/banner/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Banner updated successfully.');
        dispatch(getBanner());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBanner = createAsyncThunk('bannerSlice/deleteBanner', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/banner/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Banner deleted successfully.');
        dispatch(getBanner());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatePriorityBanner = createAsyncThunk('roleSlice/updatePriorityBanner', async (data, { dispatch }) => {
    try {
        let response = await axios.post(
            '/priority-banner',
            { bannerIds: data },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            }
        );

        toast.success(response?.data.data || 'Banner Priority updated successfully.');
        dispatch(getBanner());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const bannerSlice = createSlice({
    name: 'bannerSlice',
    initialState: {
        data: [],
        selectedBanner: {}
    },
    reducers: {
        handleSelectedBanner: (state, action) => {
            state.selectedBanner = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBanner.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getBannerById.fulfilled, (state, action) => {
            state.selectedBanner = action.payload;
            return;
        });
    }
});

export const { handleSelectedBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
