// ** Icons Import
// import { Heart } from 'react-feather';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
    const projectSettingData = useSelector((state) => state.projectSettings?.projectSetting);
    return (
        <p className="clearfix mb-0">
            <span className="float-md-start d-block d-md-inline-block mt-25">
                COPYRIGHT © {new Date().getFullYear()}
                &nbsp;
                <a href={projectSettingData?.copyRightLink} target="_blank" rel="noopener noreferrer">
                    {projectSettingData?.copyRightName}
                </a>
                <span className="d-none d-sm-inline-block">, All Rights Reserved</span>
            </span>
            {projectSettingData?.displayFooter2 && (
                <span className="float-md-end d-none d-md-block">{projectSettingData?.footerText2}</span>
            )}
        </p>
    );
};

export default Footer;
