import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// auth config import
import authConfig from '@src/auth/config.js';

// ** Service Constant Import
import service from '@src/service/constant';
import themeConfig from '../../../../configs/themeConfig';
import { isUserLoggedIn } from '../../../../utility/Utils';

const initialProjectSetting = () => {
    const data = JSON.parse(localStorage.getItem(authConfig.storageProjectSettingKeyName));
    if (data) {
        if (data.logo?.location) {
            themeConfig.app.appLogo = data.logo?.location;
        }
        if (data.favicon?.location) {
            themeConfig.app.appFavIcon = data.favicon?.location;
        }
        if (data.icon?.location) {
            themeConfig.app.appIcon = data.icon?.location;
        }
        if (data.loader) {
            themeConfig.app.appLoader = data.loader;
        }
        return data;
    }
    return [];
};

export const getPublicProjectSetting = createAsyncThunk('projectSettingSlice/getPublicProjectSetting', async () => {
    try {
        const isLoggedIn = isUserLoggedIn();

        let response = await axios.get('/public/project-setting', {
            headers: {
                'Content-Type': 'application/json',
                isLoggedIn: isLoggedIn
            },
            loader: true
        });

        // Destructure the response data
        let projectSetting = response.data.data;
        if (projectSetting.loader?.location) {
            projectSetting.loader = `${projectSetting.loader?.location}`;
        }
        if (projectSetting.logo?.location) {
            projectSetting.logo = `${projectSetting.logo?.location}`;
        }
        if (projectSetting.icon?.location) {
            projectSetting.icon = `${projectSetting.icon?.location}`;
        }
        if (projectSetting.favicon?.location) {
            projectSetting.favicon = `${projectSetting.favicon?.location}`;
        }
        localStorage.setItem(authConfig.storageProjectSettingKeyName, JSON.stringify(projectSetting));
        // return projectSetting;
        return Promise.resolve(projectSetting);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProjectSetting = createAsyncThunk('projectSettingSlice/getProjectSetting', async () => {
    try {
        const isLoggedIn = isUserLoggedIn();

        let response = await axios.get('/project-setting', {
            headers: {
                'Content-Type': 'application/json',
                isLoggedIn: isLoggedIn
            },
            loader: true
        });

        // Destructure the response data
        let projectSetting = response.data.data;
        if (projectSetting.loader?.location) {
            projectSetting.loader = `${projectSetting.loader?.location}`;
        }
        if (projectSetting.logo?.location) {
            projectSetting.logo = `${projectSetting.logo?.location}`;
        }
        if (projectSetting.icon?.location) {
            projectSetting.icon = `${projectSetting.icon?.location}`;
        }
        if (projectSetting.favicon?.location) {
            projectSetting.favicon = `${projectSetting.favicon?.location}`;
        }
        localStorage.setItem(authConfig.storageProjectSettingKeyName, JSON.stringify(projectSetting));
        // return projectSetting;
        return Promise.resolve(projectSetting);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProjectSetting = createAsyncThunk('projectSettingSlice/removeProjectSetting', async (imageType, { dispatch }) => {
    const urlParams = {
        imageType
    };
    try {
        const response = await axios.delete('/project-setting/remove-images', {
            params: urlParams,
            headers: { 'Content-Type': 'application/json' }
        });
        toast.success(response?.data?.message || 'Profile image deleted successfully!');
        dispatch(getProjectSetting());
        return response.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProjectPortal = createAsyncThunk('projectSettingSlice/createProjectPortal', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project-setting', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Settings added successfully.');
        dispatch(getProjectSetting());
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const projectSettingSlice = createSlice({
    name: 'projectSettingSlice',
    initialState: {
        projectSetting: initialProjectSetting()
    },
    reducers: {
        handleProjectSettingChange: (state, action) => {
            state.projectSetting = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectSetting.fulfilled, (state, action) => {
            projectSettingSlice.caseReducers.handleProjectSettingChange(state, action);
        });
        builder.addCase(getPublicProjectSetting.fulfilled, (state, action) => {
            projectSettingSlice.caseReducers.handleProjectSettingChange(state, action);
        });
    }
});

export const { handleProjectSettingChange } = projectSettingSlice.actions;
export default projectSettingSlice.reducer;
