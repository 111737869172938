// ** React Imports
import { Fragment, useCallback, useRef, useState } from 'react';

// ** Reactstrap Imports
import { Button, Form, Row, Col, ListGroup, ListGroupItem, Card, CardBody, CardFooter, CardTitle } from 'reactstrap';
import { Icon } from '@iconify/react';

import { useForm } from 'react-hook-form';

// ** Loader Import
import showLoader from '@components/loader';

import { useDispatch } from 'react-redux';

import { DownloadCloud, FileText, X } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import { read, utils } from 'xlsx';
import ErrorModal from './ErrorModal';
import { toast } from 'react-toastify';
import Eduvity_Label_Export from './Eduvity_Label_Export.xlsx';

// Dev Express Imports
import DataGrid, {
    SearchPanel,
    Scrolling,
    Column,
    Paging,
    Button as DevBtn,
    Pager,
    FilterRow,
    HeaderFilter,
    Selection,
    GroupPanel,
    Export,
    FilterPanel,
    FilterBuilderPopup,
    LoadPanel
} from 'devextreme-react/data-grid';
import { useNavigate } from 'react-router-dom';

import { labelBulkInsert } from '../store';
const BulkImportModal = () => {
    const navigate = useNavigate();
    const [buttonStatus, setButtonStatus] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [errorData, setErrorData] = useState([]);

    const dataGrid = useRef(null);

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 }
    };
    const pageSizes = [10, 25, 50, 100];

    const dispatch = useDispatch();

    // ** Hook Form
    const {
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    });

    const handleErrorModal = (errData) => {
        if (errData) {
            setErrorData(errData);
        } else {
            setErrorData([]);
        }
        setErrorModal(!errorModal);
    };

    const handleBulkImport = () => {
        navigate('/language-setting/label');
    };

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length && !acceptedFiles[0].name.endsWith('xlsx')) {
            return toast.error('You can only upload .xlsx');
        }

        let file = acceptedFiles[0];
        const reader = new FileReader();
        let workBook = null;
        let oldJsonData = null;

        const fileName = file.name;
        setFileName(fileName);

        let requiredHeader = ['Name', 'Type', 'Description'];

        reader.onload = () => {
            const binaryStr = reader.result;
            workBook = read(binaryStr, { type: 'binary', cellDates: true });
            oldJsonData = workBook?.SheetNames?.reduce((initial, name) => {
                const sheet = workBook?.Sheets[name];
                initial[name] = utils.sheet_to_json(sheet);
                return initial;
            }, {});
            const dataString = JSON.stringify(oldJsonData);
            let jsonArr = JSON.parse(dataString);
            const jsonData = jsonArr[Object.keys(jsonArr)[0]];

            let verifyObj = {
                invalidValues: false,
                invalidCountryCode: false
            };
            if (jsonData.length === 0) {
                return toast.warning('No data found in the Excel file.');
            }

            jsonData?.forEach((element, index) => {
                // Header validation
                let headerCheck =
                    Object.keys(element).length >= requiredHeader.length &&
                    requiredHeader.every((field) => Object.keys(element).includes(field));

                if (!headerCheck) {
                    verifyObj.invalidValues = true;
                }

                // Invalid values validation
                requiredHeader.forEach((field) => {
                    if (
                        !element.hasOwnProperty(field) ||
                        element[field] == null ||
                        element[field] == undefined ||
                        String(element[field]).trim() === ''
                    ) {
                        verifyObj.invalidValues = true;
                    }
                });

                element.id = index + 1;
            });

            let bulkData = [];

            jsonData?.forEach((data) => {
                const groupData = {
                    SrNo: data['Sr No'],
                    name: data['Name'],
                    type: data['Type'],
                    description: data['Description']
                };

                bulkData.push(groupData);
            });

            setExcelData(bulkData);
        };
        reader.readAsArrayBuffer(file);
    }, []);

    const handleRemoveFile = () => {
        setExcelData([]);
    };

    const renderFilePreview = () => {
        return <FileText size="28" />;
    };

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop,
        onDropRejected: () => {},
        maxFiles: 1
    });

    // ** Submit Function
    const onSubmit = async () => {
        if (excelData.length == 0) {
            return toast.warning('No data found in excel!');
        } else {
            const dataGridInstance = dataGrid.current.props.dataSource;

            let newData = dataGridInstance.map((item) => ({
                SrNo: item?.SrNo ? item?.SrNo : null,
                name: item?.name?.length ? item?.name : null,
                type: item?.type.toLowerCase() == 'web' ? '1' : '2',
                description: item?.description?.length ? item?.description : null
            }));

            setButtonStatus(true);
            newData?.length > 0 &&
                (await dispatch(labelBulkInsert({ dataToImport: newData }))
                    .unwrap()
                    .then((res) => {
                        navigate('/language-setting/label');
                        setButtonStatus(false);
                        showLoader(false);
                        reset();
                        setExcelData([]);
                    })
                    .catch((err) => {
                        if (err?.response?.data?.fields) {
                            handleErrorModal(err?.response?.data?.fields);
                        } else if (err?.response?.data?.data) {
                            handleErrorModal(err?.response?.data?.data);
                        }
                        setButtonStatus(false);
                        showLoader(false);
                    }));
        }
    };

    const fileList = (
        <ListGroupItem className="d-flex align-items-center justify-content-between">
            <div className="file-details d-flex align-items-center">
                <div className="file-preview me-1">{renderFilePreview(excelData[0])}</div>
                <div>
                    <p className="file-name mb-0">{fileName}</p>
                </div>
            </div>
            <Button color="danger" outline size="sm" className="btn-icon" onClick={() => handleRemoveFile(excelData[0])}>
                <X size={14} />
            </Button>
        </ListGroupItem>
    );

    const downloadExcel = () => {
        const link = document.createElement('a');
        link.href = Eduvity_Label_Export;
        link.download = 'Eduwity_Label_Export.xlsx';
        link.click();
    };

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <CardTitle className="my-auto" tag="h4">
                    <div className="d-flex justify-content-between" style={{ alignItems: 'center' }}>
                        <div className="icon">
                            <Icon className="icon-color" icon="tdesign:module" width={26} height={26} style={{ paddingRight: '6px' }} />
                            Label bulk insert
                        </div>
                        <div className="d-flex justify-content-end gap-1">
                            <div className="breadcrumb-right dropdown">
                                <Button
                                    id="download-excel-btn"
                                    className=""
                                    color="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        downloadExcel();
                                    }}
                                >
                                    <Icon icon="heroicons-outline:download" width={18} height={18} />
                                    &nbsp; <span className="align-middle ms-50">Download Sample</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardTitle>
                <Card className="mt-1">
                    <CardBody>
                        <Row>
                            <Col md="12" sm="12" className="mb-1">
                                {!excelData.length && (
                                    <div
                                        {...getRootProps({ className: 'dropzone' })}
                                        style={{
                                            border: '1px dashed #2f648e',
                                            borderRadius: '0.357rem',
                                            marginBottom: '-14px'
                                        }}
                                    >
                                        <input
                                            type="file"
                                            multiple="false"
                                            accept=".xls,.xlsx"
                                            {...getInputProps({ accept: { '.xlsx': '.xlsx' } })}
                                        />
                                        <div className="d-flex align-items-center flex-column m-5">
                                            <DownloadCloud size={64} />
                                            <p className="text-secondary">
                                                <a href="/" onClick={(e) => e.preventDefault()}>
                                                    Drop or Select File
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {excelData?.length > 0 && (
                                    <Fragment>
                                        <ListGroup className="my-2">{fileList}</ListGroup>

                                        <DataGrid
                                            dataSource={excelData}
                                            showBorders={true}
                                            showLoader={true}
                                            autoExpandAll={true}
                                            columnAutoWidth={true}
                                            keyExpr="SrNo"
                                            ref={dataGrid}
                                            editing={{
                                                mode: 'batch',
                                                allowUpdating: true
                                            }}
                                        >
                                            <LoadPanel enabled={true} />
                                            <FilterRow visible={true} />
                                            <SearchPanel visible={true} highlightCaseSensitive={false} />
                                            <HeaderFilter visible={true} />
                                            <Scrolling mode="standard" scrollByContent />
                                            <FilterPanel visible={true} />
                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />
                                            <Selection mode="multiple" />
                                            <GroupPanel visible={true} />

                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <Column dataField="SrNo" caption="Sr No" dataType="string" allowEditing={false} />
                                            <Column dataField="name" caption="Name" dataType="string" />
                                            <Column dataField="type" caption="Type" dataType="string" />
                                            <Column dataField="description" caption="Description" dataType="string" />

                                            <Paging defaultPageSize={10} />
                                            <Pager
                                                visible={true}
                                                allowedPageSizes={pageSizes}
                                                showPageSizeSelector={true}
                                                displayMode="full"
                                                showInfo={true}
                                                showNavigationButtons={true}
                                            />
                                        </DataGrid>
                                    </Fragment>
                                )}
                            </Col>
                        </Row>
                    </CardBody>

                    <CardFooter>
                        <div className="d-flex justify-content-start">
                            <div>
                                {excelData.length > 0 && (
                                    <Button disabled={buttonStatus} color="primary" type="submit" className="me-1">
                                        Submit
                                    </Button>
                                )}
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        handleBulkImport();
                                    }}
                                    outline
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <div className="justify-content-center" style={{ marginRight: '5px' }}>
                                        <Icon icon="bx:arrow-back" width={14} className="icon-size" />
                                    </div>
                                    Back
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Form>
            <ErrorModal open={errorModal} errorData={errorData} handleErrorModal={handleErrorModal} />
        </Fragment>
    );
};

export default BulkImportModal;
