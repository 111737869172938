import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';

export const getLanguage = createAsyncThunk('languageSlice/getLanguage', async () => {
    try {
        let response = await axios.get('/language', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLanguage = createAsyncThunk('languageSlice/createLanguage', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/language', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Language created successfully.');
        dispatch(getLanguage());
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const updateLanguage = createAsyncThunk('languageSlice/updateLanguage', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.put('/language/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Language updated successfully.');
        dispatch(getLanguage());
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const deleteLanguage = createAsyncThunk('languageSlice/deleteLeader', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/language/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Language deleted successfully');
        dispatch(getLanguage());
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('languageSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/language/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getLanguage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLanguageOptions = createAsyncThunk('languageSlice/getLabelOptions', async () => {
    try {
        let response = await axios.get('/language/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const languageSlice = createSlice({
    name: 'languageSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getLanguage.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getLanguageOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default languageSlice.reducer;
