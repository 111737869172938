// ** Third Party Components
import { ChevronDown } from 'react-feather';

// ** Reactstrap Imports
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { Icon } from '@iconify/react';

const FormModal = ({ open, handleErrorModal, errorData }) => {
    const [currentPage, setCurrentPage] = useState(0);
    let itemsPerPage = 3;
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = errorData?.length > 0 && errorData?.slice(startIndex, endIndex);
    const handlePagination = (page) => setCurrentPage(page.selected);
    const columns = [
        {
            name: 'Sr No',
            maxWidth: '60px',
            selector: (row) => row?.SrNo
        },
        {
            name: 'Error',
            cell: (row) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {row.messages.split(',').map((message, index) => (
                        <span key={index}>
                            {message.trim()}
                            <br />
                        </span>
                    ))}
                </div>
            )
        }
    ];
    return (
        <Modal isOpen={open} className="modal-dialog-centered" size="lg">
            <ModalHeader className="mb-1" toggle={handleErrorModal} tag="div">
                <div className="d-flex align-items-center">
                    <Icon icon="ic:baseline-error" width={25} height={20} />
                    <h5 className="modal-title">Error</h5>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="react-dataTable">
                    <DataTable
                        responsive
                        noHeader
                        columns={columns}
                        className="react-dataTable"
                        sortIcon={<ChevronDown size={10} />}
                        data={currentItems}
                    />
                </div>
                {errorData && errorData?.length !== 0 && (
                    <ReactPaginate
                        previousLabel=""
                        nextLabel=""
                        forcePage={currentPage}
                        onPageChange={handlePagination}
                        pageCount={Math.ceil(errorData?.length / itemsPerPage) || 1}
                        breakLabel={'...'}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={2}
                        activeClassName="active"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextLinkClassName="page-link"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextClassName="page-item next-item"
                        previousClassName="page-item prev-item"
                        containerClassName={
                            'pagination justify-content-center pagination-separated mb-0 react-paginate separated-pagination pagination-md'
                        }
                    />
                )}
            </ModalBody>
        </Modal>
    );
};

export default FormModal;
