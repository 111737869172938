// ** Reducers Imports
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import moduleSlice from '../views/Setup/ModuleManage/store';
import module from '../views/Setup/ModuleManage/store';
// import roles from '../views/Setup/RoleRights/Role/store';
import institutes from '../views/Institute/store';
import server from '../views/Server/store';
// import TemplateModal from '../views/ImageTemplate/store';
import config from '../views/Settings/Configuration/store/index';
import templateModalData from '../views/ImageTemplate/store';
import imageTemplateByCategory from '../views/ImageTemplate/store';
import banner from '../views/Banner/store';
import festival from '../views/Festival/store';
import menuOrder from '../views/Settings/MenuOrder/store';
import projectSettings from '../views/Settings/ProjectSetting/store';
import templateGallery from '../views/Settings/TemplateGallery/store';
import Dashboard from '../views/Dashboard/store/index';
import language from '../views/LanguageSetting/Language/store';
import label from '../views/LanguageSetting/Label/store';
import translation from '../views/LanguageSetting/Translation/store';

const rootReducer = {
    Dashboard,
    auth,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    module,
    institutes,
    server,
    config,
    templateModalData,
    imageTemplateByCategory,
    banner,
    festival,
    menuOrder,
    projectSettings,
    templateGallery,
    language,
    label,
    translation
};

export default rootReducer;
