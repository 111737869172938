import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

import themeConfig from '../../../../configs/themeConfig';

// ** get all mdule data
export const getAllModule = createAsyncThunk('moduleSlice/getAllModule', async () => {
    try {
        let response = await axios.get('/module', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
// ** update drag and drop priority
export const updatePriority = createAsyncThunk('moduleSlice/updatePriority', async (data, { dispatch }) => {
    try {
        let response = await axios.put(
            '/module/update-parent/' + data?.moduleId,
            { parentId: data?.parentId },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        toast.success(response?.data?.message || 'Module Shifted  successfully.');
        await dispatch(getAllModule());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('moduleSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/role/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        await dispatch(getRole());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

const initialModulePageActiveTab = () => {
    const item = window.localStorage.getItem('modulePageActiveTab');

    return item ? JSON.parse(item) : themeConfig.layout.modulePageActiveTab;
};

export const moduleSlice = createSlice({
    name: 'moduleSlice',
    initialState: {
        options: [],
        selectedRole: {},
        module: [],
        modulePageActiveTab: initialModulePageActiveTab()
    },
    reducers: {
        handleModuleTab: (state, action) => {
            state.modulePageActiveTab = action.payload;
            localStorage.setItem('modulePageActiveTab', JSON.stringify(action.payload));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllModule.fulfilled, (state, action) => {
            state.module = action?.payload;
            return;
        });
    }
});

export const { handleModuleTab } = moduleSlice.actions;

export default moduleSlice.reducer;
