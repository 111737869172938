import { useEffect, useState } from 'react';

// ** Reactstrap Imports
import { Modal, Input, Label, Button, ModalHeader, ModalBody, Form, FormFeedback, Row, Col, ModalFooter } from 'reactstrap';

// ** Third Party Components
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import Select from 'react-select';
import { selectThemeColors } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import classNames from 'classnames';
import { handleServerError } from '../../../../service/common';
import { createTranslation, updateTranslation } from '../store';
import { getLabelOptions } from '../../Label/store';
import { getLanguageOptions } from '../../Language/store';
import { toast } from 'react-toastify';
const TransactionModal = ({ open, handleModal, translationData }) => {
    const languageOptions = useSelector((state) => state?.language?.options);
    // dispatch object
    const dispatch = useDispatch();

    // stated
    const [buttonStatus, setButtonStatus] = useState(false);
    const [labelOption, setLabelOption] = useState([]);

    // Yup validations
    var validationObj = {
        value: yup.string().required('Value is required.').nullable(),
        languageId: yup
            .object()
            .shape({
                value: yup.string().required('Language is required.'),
                label: yup.string()
            })
            .required('Language is required.')
            .nullable(),
        type: yup
            .object()
            .shape({
                value: yup.string().required('Type is required.'),
                label: yup.string()
            })
            .required('Type is required.')
            .nullable(),
        labelId: yup
            .object()
            .shape({
                value: yup.string().required('Label is required.'),
                label: yup.string()
            })
            .required('Label is required.')
            .nullable()
    };
    const validationScheme = yup.object().shape(validationObj);

    // Hook Form
    const {
        register,
        reset,
        handleSubmit,
        setError,
        setValue,
        control,
        formState: { errors, isDirty }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    const typeOptions = [
        { value: '1', label: 'Web' },
        { value: '2', label: 'Mobile' }
    ];

    const onSubmit = async (data) => {
        setButtonStatus(true);
        const groupData = {
            languageId: data?.languageId?.value,
            type: data?.type?.value,
            labelId: data?.labelId?.value,
            value: data?.value
        };

        if (!isDirty) {
            toast.warning('No changes occurred');
            setButtonStatus(false);
            return;
        }
        if (translationData?.id) {
            await dispatch(updateTranslation({ id: translationData?.id, data: groupData }))
                .unwrap()
                .then((res) => {
                    handleModal();
                    setButtonStatus(false);
                })
                .catch((err) => {
                    setButtonStatus(false);
                });
        } else {
            // Create new Translation
            await dispatch(createTranslation(groupData))
                .unwrap()
                .then((res) => {
                    handleModal();
                    setButtonStatus(false);
                })
                .catch((err) => {
                    if (err?.response?.data) {
                        handleServerError(err, setError);
                    }
                    setButtonStatus(false);
                });
        }
    };

    // register form fields
    const { ref: refValue, ...restValue } = register('value');

    //** get All Translation options Data
    const getAllLanguageOptionData = async () => {
        await dispatch(getLanguageOptions());
    };

    //** get All Translation options Data
    const getAllLabelOptionData = async (data) => {
        await dispatch(getLabelOptions({ type: data }))
            .unwrap()
            .then((res) => {
                setLabelOption(res);
            })
            .catch((err) => {
                setLabelOption([]);
            });
    };

    useEffect(() => {
        if (open == true) {
            getAllLanguageOptionData();
        }
    }, [open]);

    useEffect(() => {
        const selectedType = typeOptions.find((option) => option.value === translationData?.Label?.type);
        reset({
            languageId: translationData?.languageId
                ? {
                      value: translationData.Language?.id ? translationData.Language?.id : null,
                      label: translationData.Language?.name ? translationData.Language?.name : null
                  }
                : null,
            type: selectedType || null,

            labelId: translationData?.labelId
                ? {
                      value: translationData.Label?.id ? translationData.Label?.id : null,
                      label: translationData.Label?.name ? translationData.Label?.name : null
                  }
                : null,
            value: translationData?.value || null
        });

        translationData?.Label?.type && getAllLabelOptionData(translationData?.Label?.type);
    }, [translationData]);

    return (
        <Modal isOpen={open} size="lg" className="modal-dialog-centered" contentClassName=" d-flex pt-0">
            <ModalHeader toggle={handleModal} tag="div">
                <div className="icon d-flex justify-content-center" style={{ alignItems: 'center' }}>
                    <Icon className="icon-color" icon="ion:language" width={26} height={26} style={{ paddingRight: '6px' }} />
                    <h5 className="modal-title" style={{ margin: 0 }}>
                        {translationData?.id ? 'Edit Translation' : 'Add Translation'}
                    </h5>
                </div>
            </ModalHeader>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <Row>
                        <Col md="6" sm="12" className="mb-1">
                            <Label>
                                Language<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="languageId"
                                control={control}
                                name="languageId"
                                render={({ field }) => (
                                    <Select
                                        id="languageId"
                                        classNamePrefix="select"
                                        theme={selectThemeColors}
                                        options={languageOptions}
                                        className={classNames('react-select', {
                                            'is-invalid': errors.languageId?.message
                                        })}
                                        {...field}
                                    />
                                )}
                            />
                            {errors.languageId && <FormFeedback>{errors.languageId.message}</FormFeedback>}
                        </Col>
                        <Col md="6" sm="12" className="mb-1">
                            <Label>
                                Type<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="type"
                                control={control}
                                name="type"
                                render={({ field }) => (
                                    <Select
                                        id="type"
                                        classNamePrefix="select"
                                        theme={selectThemeColors}
                                        options={typeOptions}
                                        className={classNames('react-select', {
                                            'is-invalid':
                                                errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue('labelId', null);
                                            if (e?.value) {
                                                getAllLabelOptionData(e?.value);
                                            } else {
                                                setLabelOption([]);
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors && errors?.type && (
                                <FormFeedback>
                                    {errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message}
                                </FormFeedback>
                            )}
                        </Col>

                        <Col md="12" sm="12" className="mb-1">
                            <Label>
                                Label<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="labelId"
                                control={control}
                                name="labelId"
                                render={({ field }) => (
                                    <Select
                                        id="labelId"
                                        name="labelId"
                                        classNamePrefix="select"
                                        options={labelOption}
                                        theme={selectThemeColors}
                                        className={classNames('react-select', {
                                            'is-invalid':
                                                errors.labelId?.message || errors.labelId?.label.message || errors.labelId?.value.message
                                        })}
                                        {...field}
                                    />
                                )}
                            />
                            {errors && errors?.labelId && (
                                <FormFeedback>
                                    {errors?.labelId?.message || errors?.labelId?.label?.message || errors?.labelId?.value?.message}
                                </FormFeedback>
                            )}
                        </Col>

                        <Col md="12" sm="12" className="mb-1">
                            <Label>
                                Value<span className="label-css">*</span>
                            </Label>
                            <Input
                                id="value"
                                name="value"
                                type="text"
                                placeholder="Value"
                                invalid={errors.value && true}
                                {...restValue}
                                innerRef={refValue}
                            />
                            {errors && errors.value && <FormFeedback>{errors.value.message}</FormFeedback>}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={buttonStatus} color="primary" type="submit">
                        Submit
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={() => {
                            reset();
                            setButtonStatus(false);
                            translationData?.Label?.type ? getAllLabelOptionData(translationData?.Label?.type) : setLabelOption([]);
                        }}
                    >
                        Reset
                    </Button>
                    <Button color="secondary" onClick={handleModal} outline>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default TransactionModal;
