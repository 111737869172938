import React, { Fragment, useState, useRef, useCallback } from 'react';

// ** React Router Dom
import { useNavigate } from 'react-router-dom';

// ** Feather Icons
import { X, FileText, DownloadCloud } from 'react-feather';

// ** Reactstrap Imports
import {
    Card,
    Button,
    CardTitle,
    CardHeader,
    Label,
    CardBody,
    Row,
    Col,
    FormFeedback,
    ListGroupItem,
    ListGroup,
    CardFooter
} from 'reactstrap';
import Select from 'react-select';
import { selectThemeColors } from '@utils';

// Dev Express Imports
import DataGrid from 'devextreme-react/data-grid';
import {
    SearchPanel,
    Scrolling,
    Column,
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    Button as DevBtn,
    Selection,
    GroupPanel,
    Export,
    FilterPanel,
    FilterBuilderPopup,
    LoadPanel
} from 'devextreme-react/data-grid';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';

// ** permission modules

import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { deleteLabel, exportToExcelLabel, getLabel, labelBulkUpdate } from '../store';
import { toast } from 'react-toastify';
import TransactionModal from './Modal';
import BulkUpdateErrorModal from './BulkUpdateErrorModal';

// ** Loader Import
import showLoader from '@components/loader';

// ** Common Delete Modal
import DeleteModal from '@components/delete-modal';
import { read, utils } from 'xlsx';

const BulkUpdate = () => {
    // navigate object
    const navigate = useNavigate();

    const {
        register,
        setValue,
        control,
        setError,
        getValues,
        formState: { errors, isDirty }
    } = useForm({
        mode: 'onChange'
    });

    // dispatch object
    const dispatch = useDispatch();

    // ** DataGrid reference
    const dataGrid = useRef(null);

    // grid settings
    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 }
    };
    const pageSizes = [10, 25, 50, 100];

    // ** States
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [typeValue, setTypeValue] = useState(null);
    const [translationData, setTranslationData] = useState({});
    const [languageId, setLanguageId] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const [excelData, setExcelData] = useState([]);

    const [fileName, setFileName] = useState('');

    const handleLabelData = async () => {
        const typeValue = getValues('type');
        if (!typeValue) {
            setError('type', {
                type: 'custom',
                message: 'Type is required.'
            });
        }

        if (languageId && typeValue) {
            const data = { type: typeValue?.value, languageId: languageId };
            if (!isDirty) {
                setButtonStatus(false);
                return;
            }
            try {
                await dispatch(getLabel(data))
                    .unwrap()
                    .then((res) => {
                        setDataSource(res);
                    })
                    .catch((err) => {
                        console.log('err', err);
                    });
            } catch (err) {
                console.log('err', err);
            }
        }
    };

    const onSubmit = async () => {
        const dataGridInstance = dataGrid.current.props.dataSource;

        if (dataGridInstance?.length === 0) {
            return toast.warning('No data found in excel!');
        }

        const excelData = dataGridInstance.map((row) => ({
            id: row.ID,
            name: row.name,
            description: row.description,
            type: row.type === 'Web' ? 1 : 2
        }));

        showLoader(true);
        setButtonStatus(true);

        try {
            await dispatch(labelBulkUpdate({ dataToUpdate: excelData }))
                .unwrap()
                .then((res) => {
                    setButtonStatus(false);
                    showLoader(false);
                    navigate('/language-setting/label');
                    setExcelData([]);
                })
                .catch((err) => {
                    if (err?.response?.data?.fields) {
                        handleErrorModal(err?.response?.data?.fields);
                    }
                    setButtonStatus(false);
                    showLoader(false);
                });
        } catch (error) {
            setButtonStatus(false);
            showLoader(false);
        }
    };
    const typeOptions = [
        { value: '1', label: 'Web' },
        { value: '2', label: 'Mobile' }
    ];
    // ** Function to handle Modal toggle
    const handleModal = async (data) => {
        if (data) {
            setTranslationData(data);
        } else {
            setTranslationData({});
        }
        setModal(!modal);
    };
    const handleDeleteModal = (row) => {
        deleteId ? setDeleteId() : setDeleteId(row);
        setDeleteModal(!deleteModal);
    };

    const deleteFunction = async () => {
        return await dispatch(deleteLabel(deleteId))
            .unwrap()
            .then((res) => {
                handleDeleteModal();
            })
            .catch((err) => {});
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length && !acceptedFiles[0].name.endsWith('xlsx')) {
            return toast.error('You can only upload .xlsx');
        }

        let file = acceptedFiles[0];
        const reader = new FileReader();
        let workBook = null;
        let oldJsonData = null;

        const fileName = file.name;
        setFileName(fileName);

        let requiredHeader = ['Name', 'Type', 'Description'];

        reader.onload = () => {
            const binaryStr = reader.result;
            workBook = read(binaryStr, { type: 'binary', cellDates: true });
            oldJsonData = workBook?.SheetNames?.reduce((initial, name) => {
                const sheet = workBook?.Sheets[name];
                initial[name] = utils.sheet_to_json(sheet);
                return initial;
            }, {});
            const dataString = JSON.stringify(oldJsonData);
            let jsonArr = JSON.parse(dataString);
            const jsonData = jsonArr[Object.keys(jsonArr)[0]];

            let verifyObj = {
                invalidValues: false,
                invalidCountryCode: false
            };
            if (jsonData.length === 0) {
                return toast.warning('No data found in the Excel file.');
            }

            jsonData?.forEach((element, index) => {
                let headerCheck =
                    Object.keys(element).length >= requiredHeader.length &&
                    requiredHeader.every((field) => Object.keys(element).includes(field));

                if (!headerCheck) {
                    verifyObj.invalidValues = true;
                }

                // Invalid values validation
                requiredHeader.forEach((field) => {
                    if (
                        !element.hasOwnProperty(field) ||
                        element[field] == null ||
                        element[field] == undefined ||
                        String(element[field]).trim() === ''
                    ) {
                        verifyObj.invalidValues = true;
                    }
                });
            });

            let bulkData = [];

            jsonData?.forEach((data) => {
                const groupData = {
                    ID: data['id'],
                    name: data['Name'],
                    type: data['Type'],
                    description: data['Description']
                };

                bulkData.push(groupData);
            });

            setExcelData(bulkData);
        };
        reader.readAsArrayBuffer(file);
    }, []);

    const handleRemoveFile = () => {
        setExcelData([]);
    };

    const renderFilePreview = () => {
        return <FileText size="28" />;
    };

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop,
        onDropRejected: () => {},
        maxFiles: 1
    });

    const fileList = (
        <ListGroupItem className="d-flex align-items-center justify-content-between">
            <div className="d-flex justify-content-between" style={{ alignItems: 'center' }}>
                <div className="file-preview me-1">{renderFilePreview(excelData[0])}</div>
                <div>
                    <p className="file-name mb-0">{fileName}</p>
                </div>
            </div>
            <Button color="danger" outline size="sm" className="btn-icon" onClick={() => handleRemoveFile(excelData[0])}>
                <X size={14} />
            </Button>
        </ListGroupItem>
    );

    const handleDownloadExcel = async () => {
        const typeValue = getValues('type');
        if (!typeValue) {
            setError('type', {
                type: 'custom',
                message: 'Type is required.'
            });
        } else {
            await dispatch(exportToExcelLabel({ type: typeValue?.value }))
                .unwrap()
                .then((res) => {
                    // console.log(res, 'res');
                })
                .catch((err) => {
                    console.log(err, 'err');
                });
        }
    };

    const clearFunction = () => {
        setValue('type', null);
        setExcelData([]), setLanguageId(null);
        setTypeValue(null);
        setDataSource([]);
    };

    const handleErrorModal = (errData) => {
        if (errData) {
            setErrorData(errData);
        } else {
            setErrorData([]);
        }
        setErrorModal(!errorModal);
    };

    const handleBulkUpdate = () => {
        navigate('/language-setting/label');
    };

    const isSubmitDisabled = !typeValue || excelData.length === 0;

    return (
        <Fragment>
            <Card>
                <CardHeader className="flex-md-row flex-colum align-md-items-center align-items-start border-bottom">
                    <CardTitle className="my-auto" tag="h4">
                        <div className="icon">
                            <Icon className="icon-color" icon="ion:language" width={26} height={26} style={{ paddingRight: '6px' }} />
                            Label Bulk Update
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardBody className="mt-2">
                    <Row className="">
                        <Col md="2" sm="12" className="">
                            <Label>
                                Type<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="type"
                                control={control}
                                name="type"
                                render={({ field }) => (
                                    <Select
                                        id="type"
                                        classNamePrefix="select"
                                        theme={selectThemeColors}
                                        options={typeOptions}
                                        {...register('type')}
                                        className={classNames('react-select', {
                                            'is-invalid':
                                                errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (e?.value) {
                                                setTypeValue(e?.value);
                                            } else {
                                                setTypeValue(null);
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors && errors?.type && (
                                <FormFeedback>
                                    {errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message}
                                </FormFeedback>
                            )}
                        </Col>

                        <Col
                            md="4"
                            sm="12"
                            className="d-flex justify-content-start align-items-center gap-1 w-auto"
                            style={{ marginTop: '26px' }}
                        >
                            <Button
                                style={{ height: '37px', alignItems: 'center' }}
                                id="download-excel-btn"
                                className=""
                                color="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDownloadExcel();
                                }}
                                disabled={!typeValue}
                            >
                                <Icon icon="heroicons-outline:download" width={18} height={18} />
                                &nbsp; <span className="align-middle">Download Sample</span>
                            </Button>

                            <Button
                                color="danger"
                                outline
                                size="sm"
                                className="btn-icon"
                                style={{ width: '40px' }}
                                onClick={(e) => {
                                    clearFunction();
                                    e.preventDefault();
                                }}
                            >
                                <X size={20} />
                            </Button>
                        </Col>
                    </Row>

                    <CardBody>
                        <Row>
                            <Col style={{ marginBottom: '20px' }} md="12" sm="12" className="mb-1">
                                {!excelData.length && typeValue && (
                                    <div
                                        {...getRootProps({ className: 'dropzone' })}
                                        style={{
                                            border: '1px dashed #2f648e',
                                            borderRadius: '0.357rem',
                                            minHeight: '200px',
                                            marginBottom: '-14px'
                                        }}
                                    >
                                        <input
                                            type="file"
                                            multiple="false"
                                            accept=".xls,.xlsx"
                                            {...getInputProps({ accept: { '.xlsx': '.xlsx' } })}
                                        />
                                        <div className="d-flex align-items-center flex-column m-5">
                                            <DownloadCloud size={64} />
                                            <p className="text-secondary">
                                                <a href="/" onClick={(e) => e.preventDefault()}>
                                                    Drop or Select File
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {excelData?.length > 0 && (
                                    <Fragment>
                                        <ListGroup className="">{fileList}</ListGroup>

                                        <DataGrid
                                            dataSource={excelData}
                                            showBorders={true}
                                            showLoader={true}
                                            autoExpandAll={true}
                                            columnAutoWidth={true}
                                            keyExpr="ID"
                                            ref={dataGrid}
                                            editing={{
                                                mode: 'batch',
                                                allowUpdating: true
                                            }}
                                        >
                                            <LoadPanel enabled={true} />
                                            <FilterRow visible={true} />
                                            <SearchPanel visible={true} highlightCaseSensitive={false} />
                                            <HeaderFilter visible={true} />
                                            <Scrolling mode="standard" scrollByContent />
                                            <FilterPanel visible={true} />
                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />
                                            <Selection mode="multiple" />
                                            <GroupPanel visible={true} />

                                            <Export enabled={false} allowExportSelectedData={false} />

                                            {/* Editable columns */}
                                            <Column dataField="name" caption="Name" dataType="string" allowEditing={true} />
                                            <Column dataField="description" caption="Description" dataType="string" />
                                            <Column dataField="type" caption="Type" dataType="string" allowEditing={false} />

                                            <Paging defaultPageSize={10} />
                                            <Pager
                                                visible={true}
                                                allowedPageSizes={pageSizes}
                                                showPageSizeSelector={true}
                                                displayMode="full"
                                                showInfo={true}
                                                showNavigationButtons={true}
                                            />
                                        </DataGrid>
                                    </Fragment>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="pb-0 ">
                        <div className="d-flex justify-content-start">
                            <div>
                                {excelData.length > 0 && (
                                    <Button
                                        disabled={!typeValue || isSubmitDisabled}
                                        color="primary"
                                        type="submit"
                                        className="me-1"
                                        onClick={onSubmit}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        handleBulkUpdate();
                                    }}
                                    outline
                                    className="d-flex justify-content-center"
                                >
                                    <div className="justify-content-center" style={{ marginRight: '5px' }}>
                                        <Icon icon="bx:arrow-back" width={14} className="icon-size" />
                                    </div>
                                    Back
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </CardBody>
            </Card>
            <TransactionModal open={modal} handleModal={handleModal} translationData={translationData} />
            <DeleteModal open={deleteModal} title="Delete Translation " handleModal={handleDeleteModal} deleteFunction={deleteFunction} />
            <BulkUpdateErrorModal open={errorModal} errorData={errorData} handleErrorModal={handleErrorModal} />
        </Fragment>
    );
};

export default BulkUpdate;
