module.exports = {
    institute: '6a574ff9-5219-4525-8a11-045a8dc1a601',
    banner: '7f950d65-e3a9-414d-b385-e29ddb7cece5',
    festival: '8eab0542-526a-4191-b9a0-cd188c89b3c0',
    setting: 'f993222c-c535-41f7-b69f-96178eb687e9',
    setting_configuration: 'd809e751-46c8-4bd8-9567-54f5fa118616',
    setting_project_setting: 'afc98736-4f34-49f4-b176-175bcd261438',
    setting_email_template: '55d6d849-90f5-4631-a7ff-210b5d2158be',
    setting_menu_order: 'c7260d45-109d-4b47-9fcf-2f261a37e5a7',
    image_template: 'bde6f5ae-a362-41ca-ac48-3378e0fe81c4',
    setup: 'ec3ecc29-356d-440e-9ec2-2095f43f230e',
    setup_role: '7bec7bd2-ba7d-4352-8671-07c3cb794db2',
    setup_role_add: 'a3efb266-15b6-4148-a777-47b9ee675a46',
    setup_role_edit: '5b87a5e6-7d58-4af6-b6ad-0128fbf1b6b7',
    setup_role_delete: '80e81b90-6945-48ce-9448-c31af390246a',
    setup_role_view: '64f89ce8-862e-40ff-87a9-4e975301b400',
    setup_role_assign_permission: 'f80cbe1f-8cca-4cf7-b5f0-032c73ed8726',
    setup_role_update_hierarchy: '4740409a-5fd6-4479-b8ba-a6031481e8a5',
    setup_user: '648c8541-2100-4297-815e-c512063746f3',
    server: '87a4974a-4a7b-4f0c-b658-062b38bc9251',
    dashboard: '870fca4d-2091-4fad-b40a-1be70f3f740a',
    language_setting: '25aff51e-dc7f-4033-8acd-dd82a7295feb',
    language_setting_language: 'e93292b2-2c43-48cf-8bbd-551a2bdf1956',
    language_setting_label: '83239f0d-c9b2-498d-8808-4a602ce43df6',
    language_setting_translation: '42da5325-d662-4d03-93b4-40c0a147e8f0'
};
