import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getEmailConfiguration = createAsyncThunk('configurationSlice/getEmailConfiguration', async () => {
    try {
        let response = await axios.get('/email-configuration', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmailConfiguration = createAsyncThunk('configurationSlice/createEmailConfiguration', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/email-configuration', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Email Configuration created successfully.');
        await dispatch(getEmailConfiguration());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteEmailConfig = createAsyncThunk('configurationSlice/deleteEmailConfig', async (id, { dispatch }) => {
    try {
        let response = await axios.delete('/email-configuration/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Email Configuration deleted successfully.');
        await dispatch(getEmailConfiguration());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('configurationSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/email-configuration/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        await dispatch(getEmailConfiguration());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const configurationSlice = createSlice({
    name: 'configurationSlice',
    initialState: {
        emailConfigurationData: []
    },

    extraReducers: (builder) => {
        builder.addCase(getEmailConfiguration.fulfilled, (state, action) => {
            state.emailConfigurationData = action?.payload;
            return;
        });
    }
});

export default configurationSlice.reducer;
