import { Circle, Settings } from 'react-feather';

// permission modules
import modules from '@src/service/modules';

const manageModuleNav = [];

if (process.env.NODE_ENV === 'development') {
    manageModuleNav.push({
        id: 'module',
        title: 'Module',
        icon: <Circle size={12} />,
        navLink: '/module'
    });
}

export default [
    {
        id: 'settings',
        title: 'Settings',
        icon: <Settings size={20} />,
        // dbId: modules.settings,
        children: [
            {
                id: 'configuration',
                title: 'Configuration',
                icon: <Circle size={12} />,
                navLink: '/settings/configuration'
                // dbId: modules.settings
            },
            {
                id: 'project-setting',
                title: 'Project Setting',
                icon: <Circle size={12} />,
                navLink: '/settings/project-setting'
                // dbId: modules.settings
            },
            {
                id: 'email-templates',
                title: 'Email Templates',
                icon: <Circle size={12} />,
                navLink: '/settings/email-templates'
                // dbId: modules.settings
            },
            {
                id: 'menu-order',
                title: 'Menu Order',
                icon: <Circle size={12} />,
                navLink: '/settings/menu-order'
            },
            ...manageModuleNav
        ]
    }
];
