import { lazy } from 'react';

const Banner = lazy(() => import('../../views/Banner'));

const BannerRoutes = [
    {
        path: '/banner',
        element: <Banner />
    }
];

export default BannerRoutes;
