import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getTranslation = createAsyncThunk('translationSlice/getTranslation', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/translation' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        // dispatch(removeSelectedTranslation());
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createTranslation = createAsyncThunk('translationSlice/createTranslation', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/translation', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Translation created successfully.');
        dispatch(getTranslation());
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const translationBulkInsert = createAsyncThunk(
    'translationSlice/translationBulkInsert',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/translation/bulk-insert', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Bulk Import successfully !');
            await dispatch(getTranslation());
            return Promise.resolve(response);
        } catch (err) {
            // if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            //     toast.error(err?.response?.data?.message || 'Something went wrong!');
            // }
            console.log(err, 'err');
            return rejectWithValue(err);
        }
    }
);

export const translationBulkUpdate = createAsyncThunk('labelSlice/translationBulkUpdate', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('translation/bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Bulk Update successfully !');
        await dispatch(getTranslation());
        return Promise.resolve(response);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const deleteTranslation = createAsyncThunk('translationSlice/deleteLeader', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/translation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Translation deleted successfully');
        dispatch(getTranslation());
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// bulk delete
export const deleteBulkTranslation = createAsyncThunk('translationSlice/deleteBulkTranslation', async (translationIds, { dispatch }) => {
    try {
        let response = await axios.post('/translation/bulk-delete', translationIds, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Translation deleted successfully.');
        await dispatch(getTranslation());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTranslation = createAsyncThunk('translationSlice/updateTranslation', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.put('/translation/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Translation updated successfully.');
        dispatch(getTranslation());
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const translationSlice = createSlice({
    name: 'translationSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getTranslation.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default translationSlice.reducer;
