export const handleServerError = (error, setError) => {
    if (Object.prototype.hasOwnProperty.call(error?.response?.data, 'field')) {
        setError(error.response.data.field, {
            type: 'server error',
            message: error.response.data.message
        });
    } else if (
        Object.prototype.hasOwnProperty.call(error.response.data, 'fields') &&
        Array.isArray(error.response.data.fields) &&
        error.response.data.fields.length > 0
    ) {
        error.response.data.fields.forEach(({ msg, param }) =>
            setError(param, {
                type: 'server error',
                message: msg
            })
        );
    }
};
