// ** Icons Import
import { Icon } from '@iconify/react';

export default [
    {
        id: 'banner',
        title: 'Banner',
        icon: <Icon icon="material-symbols:planner-banner-ad-pt" width="30" height="30" />,
        navLink: '/banner'
    }
];
