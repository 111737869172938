import { lazy } from 'react';

const Server = lazy(() => import('../../views/Server'));
const MigrateServer = lazy(() => import('../../views/Server/components/MigrateServer/index'));

const ServerRoutes = [
    {
        path: '/server',
        element: <Server />
    },
    {
        path: '/server/:id',
        element: <MigrateServer />
    }
];

export default ServerRoutes;
