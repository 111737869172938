// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink';
import VerticalNavMenuGroup from './VerticalNavMenuGroup';
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader';

// ** Utils
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@layouts/utils';
import { useSelector } from 'react-redux';

const VerticalMenuNavItems = (props) => {
    // ** Components Object
    const Components = {
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
        VerticalNavMenuSectionHeader
    };

    const permissionSlice = useSelector((state) => state?.permission?.permission);
    const canView = (id) => {
        if (id) {
            return permissionSlice?.includes(String(id));
        }
    };

    // ** Render Nav Menu Items
    const RenderNavItems = props.items.map((item, index) => {
        const TagName = Components[resolveNavItemComponent(item)];

        if (item.children) {
            if (item?.dbId) {
                return canView(item?.dbId) && <TagName item={item} index={index} key={item.id} {...props} />;
            }
            return <TagName item={item} index={index} key={item.id} {...props} />;
        }

        if (item?.dbId) {
            return canView(item?.dbId) && <TagName key={item.id || item.header} item={item} {...props} />;
        }
        return <TagName key={item.id || item.header} item={item} {...props} />;
    });

    return RenderNavItems;
};

export default VerticalMenuNavItems;
