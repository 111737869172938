import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';

export const getTemplateCategory = createAsyncThunk('templateCategorySlice/getTemplateCategory', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/image-template-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getImageTemplate = createAsyncThunk('templateCategorySlice/getImageTemplate', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/image-template', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTemplateCategory = createAsyncThunk(
    'templateCategorySlice/createTemplateCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            showLoader(true);
            let response = await axios.post('/image-template-category', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            showLoader(false);
            toast.success(response?.data?.message || 'Template Category added successfully.');
            await dispatch(getTemplateCategory());
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            return rejectWithValue(err);
        }
    }
);

export const createImageTemplate = createAsyncThunk(
    'templateCategorySlice/createImageTemplate',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/image-template', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Image Template added successfully.');
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            return rejectWithValue(err);
        }
    }
);

export const updateTemplateCategory = createAsyncThunk(
    'templateCategorySlice/updateTemplateCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/image-template-category/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
                // loader: true
            });
            toast.success(response?.data?.message || 'Template Category updated successfully.');
            dispatch(getTemplateCategory());
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            return rejectWithValue(err);
        }
    }
);

export const updateImageTemplate = createAsyncThunk(
    'templateCategorySlice/updateImageTemplate',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/image-template/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Image Template updated successfully.');
            dispatch(getImageTemplate());
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            return rejectWithValue(err);
        }
    }
);

export const deleteTemplateCategory = createAsyncThunk('templateCategorySlice/deleteTemplateCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/image-template-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Template Category deleted successfully.');
        await dispatch(getTemplateCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteImageTemplate = createAsyncThunk('templateCategorySlice/deleteImageTemplate', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/image-template/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Image Template deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getImageTemplateCategoryOptions = createAsyncThunk('templateCategorySlice/getImageTemplateCategoryOptions', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/image-template-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getImageTemplateByCategory = createAsyncThunk('templateCategorySlice/getImageTemplateByCategory', async (id) => {
    try {
        // showLoader(true);
        let response = await axios.get(`/image-template?categoryId=${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatePriorityImageTemplate = createAsyncThunk('templateCategorySlice/updatePriorityImageTemplate', async (data) => {
    try {
        let response = await axios.post('/priority-image-template', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Image Template Priority updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const templateCategory = createSlice({
    name: 'templateCategorySlice',
    initialState: {
        data: [],
        category: [],
        template: []
    },
    reducers: {
        handleRemoveTemplate: (state) => {
            state.template = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTemplateCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getImageTemplateCategoryOptions.fulfilled, (state, action) => {
            state.category = action?.payload;
            return;
        });
        builder.addCase(getImageTemplateByCategory.fulfilled, (state, action) => {
            state.template = action?.payload;
            return;
        });
    }
});

export const { handleRemoveTemplate } = templateCategory.actions;

export default templateCategory.reducer;
