import { DefaultRoute } from '../router/routes';

// Auth config import
import authConfig from '@src/auth/config.js';

// secure ls
import { secureGet } from '@src/service/secureLs';
import { Column } from 'devextreme-react/data-grid';
// excel to export
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date();
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value;
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting = { month: 'short', day: 'numeric' };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' };
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
    if (localStorage.getItem(authConfig.storageUserDataKeyName) && localStorage.getItem(authConfig.storageTokenKeyName)) {
        return true;
    }
    return false;
};
// export const getUserData = () => JSON.parse(localStorage.getItem(authConfig.storageUserDataKeyName));
export const getUserData = () => secureGet(authConfig.storageUserDataKeyName);

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    return DefaultRoute;
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
});

export const ExcelExport = (event, fileName = 'Data') => {
    const component = event.component;
    const selectedRows = component.getSelectedRowsData();

    if (event.selectedRowsOnly && selectedRows.length === 0) {
        toast.warning('Please select at least one row to export.');
        event.cancel = true;
        return;
    }

    if (!fileName || fileName.length === 0) fileName = 'Data';
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main Sheet');

    exportDataGrid({
        component: event.component,
        worksheet,
        autoFilterEnabled: true
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        });
    });
    event.cancel = true;

    exportDataGrid({
        component: event.component,
        worksheet,
        autoFilterEnabled: true
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Data.xlsx');
        });
    });
    event.cancel = true;
};

export const checkPermission = (userPermission, permissionsToCheck, useAndCondition = false) => {
    if (useAndCondition) {
        return permissionsToCheck.every((permission) => userPermission?.includes(permission));
    } else {
        return permissionsToCheck.some((permission) => userPermission?.includes(permission));
    }
};

export const getSessionStorage = (key) => {
    if (typeof window !== 'undefined') {
        const item = sessionStorage.getItem(key);
        return item;
    } else {
        return null;
    }
};

export const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};

export const truncateTitle = (title, maxLength) => {
    if (!title) return '-';
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
};

export const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

export const CommonGridColumns = (flags = { createdAt: true, createdBy: true, updatedAt: true, updatedBy: true }) => {
    return [
        flags.createdAt && (
            <Column key="createdAt" dataField="createdAt" caption="Created At" dataType="date" format="dd/MM/yyyy HH:mm:ss" />
        ),

        flags.createdBy && (
            <Column
                dataField="CreatedByUser.fullName"
                caption="Created By"
                dataType="string"
                cellRender={(cellData) => {
                    const user = cellData.data;

                    if (!user.CreatedByUser) {
                        return null;
                    }

                    const imageUrl = user.CreatedByUser.profileImage ? service.API_URL + '/' + user.CreatedByUser.profileImage : null;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    // alt={user.fullName}
                                    className="common-grid-column-image"
                                />
                            ) : (
                                <div className="common-grid-column-no-image text-primary">
                                    {user.CreatedByUser?.firstName?.[0]?.toUpperCase() || ''}
                                    {user.CreatedByUser?.lastName?.[0]?.toUpperCase() || ''}
                                </div>
                            )}

                            <span>{user.CreatedByUser?.fullName}</span>
                        </div>
                    );
                }}
            />
        ),

        flags.updatedAt && (
            <Column key="updatedAt" dataField="updatedAt" caption="Updated At" dataType="date" format="dd/MM/yyyy HH:mm:ss" />
        ),

        flags.updatedBy && (
            <Column
                dataField="UpdatedByUser.fullName"
                caption="Updated By"
                dataType="string"
                cellRender={(cellData) => {
                    const user = cellData.data;

                    if (!user.UpdatedByUser) {
                        return null;
                    }

                    const imageUrl = user.UpdatedByUser.profileImage ? service.API_URL + '/' + user.UpdatedByUser.profileImage : null;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    // alt={user.fullName}
                                    className="common-grid-column-image"
                                />
                            ) : (
                                <div className="common-grid-column-no-image text-primary">
                                    {user.UpdatedByUser?.firstName?.[0]?.toUpperCase() || ''}
                                    {user.UpdatedByUser?.lastName?.[0]?.toUpperCase() || ''}
                                </div>
                            )}

                            <span>{user.UpdatedByUser?.fullName}</span>
                        </div>
                    );
                }}
            />
        )
    ].filter(Boolean);
};
