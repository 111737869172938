import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getLabel = createAsyncThunk('labelSlice/getLabel', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/label' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        // dispatch(removeSelectedLabel());
        return Promise.resolve(response.data.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLabel = createAsyncThunk('labelSlice/createLabel', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/label', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Label created successfully.');
        dispatch(getLabel());
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data?.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const labelBulkInsert = createAsyncThunk('labelSlice/labelBulkInsert', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/label/bulk-insert', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Bulk Insert successfully !');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        // if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
        //     toast.error(err?.response?.data?.message || 'Something went wrong!');
        // }
        // console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const labelBulkUpdate = createAsyncThunk('labelSlice/labelBulkUpdate', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/label/bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Bulk Update successfully !');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }

        return rejectWithValue(err);
    }
});

// bulk delete
export const deleteBulkLabel = createAsyncThunk('labelSlice/deleteBulkLabel', async (labelIds, { dispatch }) => {
    try {
        let response = await axios.post('/label/bulk-delete', labelIds, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Labels deleted successfully.');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const exportToExcelLabel = createAsyncThunk('labelSlice/exportToExcelLabel', async (data, { dispatch }) => {
    const query = queryString.stringify(data);

    try {
        let response = await axios.get('/label/export-to-excel-Labels' + `?${query}`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        const groupData = {
            completed: '1',
            acrDownloaded: '0'
        };
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `Label.xlsx`;

        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();

        return Promise.resolve({ message: 'Excel file downloaded successfully' });
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteLabel = createAsyncThunk('labelSlice/deleteLabel', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/label/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Label deleted successfully');
        dispatch(getLabel());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLabel = createAsyncThunk('labelSlice/updateLabel', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/label/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Label updated successfully.');
        dispatch(getLabel());
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const getLabelOptions = createAsyncThunk('labelSlice/getLabelOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/label/options' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const labelSlice = createSlice({
    name: 'labelSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getLabel.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getLabelOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default labelSlice.reducer;
