import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getFestival = createAsyncThunk('festivalSlice/getFestival', async () => {
    try {
        let response = await axios.get('/festival', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createFestival = createAsyncThunk('festivalSlice/createFestival', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/festival', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Festival added successfully.');
        dispatch(getFestival());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFestivalById = createAsyncThunk('festivalSlice/getFestivalById', async (id) => {
    try {
        let response = await axios.get('/festival/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFestival = createAsyncThunk('festivalSlice/updateFestival', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/festival/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Festival updated successfully.');
        dispatch(getFestival());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteFestival = createAsyncThunk('festivalSlice/deleteFestival', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/festival/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Festival deleted successfully.');
        dispatch(getFestival());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const festivalSlice = createSlice({
    name: 'festivalSlice',
    initialState: {
        data: [],
        selectedFestival: {}
    },
    reducers: {
        handleSelectedFestival: (state, action) => {
            state.selectedFestival = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFestival.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getFestivalById.fulfilled, (state, action) => {
            state.selectedFestival = action.payload;
            return;
        });
    }
});

export const { handleSelectedFestival } = festivalSlice.actions;

export default festivalSlice.reducer;
