// ** Icons Import
import { Home, Circle } from 'react-feather';
import { Icon } from '@iconify/react';

export default [
    {
        id: 'server',
        title: 'Server',
        icon: <Icon icon="mdi:server-outline" width="30" height="30" />,
        navLink: '/server'
    }
];
