import { lazy } from 'react';

const ImageTemplate = lazy(() => import('../../views/ImageTemplate/components/index.js'));

const DashboardRoutes = [
    {
        path: '/image-template',
        element: <ImageTemplate />,
        meta: {
            appLayout: true,
            className: 'email-application'
        }
    }
];

export default DashboardRoutes;
