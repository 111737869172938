import { useState } from 'react';

// ** Third Party Components
import { X } from 'react-feather';

// ** Reactstrap Imports
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteModal = ({ open, handleModal, deleteFunction, title }) => {
    // ** Custom close btn
    const CloseBtn = <X className="cursor-pointer" size={15} onClick={handleModal} />;
    const [buttonStatus, setButtonStatus] = useState(false);

    return (
        <Modal isOpen={open} toggle={handleModal} className="modal-dialog-centered modal-sm">
            <ModalHeader className="mb-1" toggle={handleModal} close={CloseBtn} tag="div">
                <h5 className="modal-title">{title ? title : 'Delete Record'}</h5>
            </ModalHeader>
            <ModalBody className="flex-grow-1">
                <p>Are you sure? </p>
                <p>Once deleted data cannot be recovered.</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={buttonStatus}
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        deleteFunction();
                    }}
                >
                    Confirm
                </Button>

                <Button color="secondary" onClick={handleModal} outline>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
