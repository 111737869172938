import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getServer = createAsyncThunk('serverSlice/getServer', async () => {
    try {
        let response = await axios.get('/server', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getServerOptions = createAsyncThunk('serverSlice/getServerOptions', async () => {
    try {
        let response = await axios.get('/server/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createServer = createAsyncThunk('serverSlice/createServer', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/server', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Server added successfully.');
        dispatch(getServer());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getServerById = createAsyncThunk('serverSlice/getServerById', async (id) => {
    try {
        let response = await axios.get('/server/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSuperPassword = createAsyncThunk('serverSlice/updateSuperPassword', async (data) => {
    try {
        let response = await axios.put('/server-super-password', data.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Super Password updated successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAdminPassword = createAsyncThunk('serverSlice/updatePassword', async (data) => {
    try {
        let response = await axios.put('/server/system-admin', data.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || ' Password updated successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateServer = createAsyncThunk('serverSlice/updateServer', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/server/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Server updated successfully.');
        dispatch(getServer());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteServer = createAsyncThunk('serverSlice/deleteServer', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/server/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Server deleted successfully.');
        dispatch(getServer());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('serverSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/server/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getServer());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const serverSlice = createSlice({
    name: 'serverSlice',
    initialState: {
        data: [],
        selectedServer: {},
        options: []
    },
    reducers: {
        handleSelectedServer: (state, action) => {
            state.selectedServer = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getServer.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getServerById.fulfilled, (state, action) => {
            state.selectedServer = action.payload;
            return;
        });
        builder.addCase(getServerOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
    }
});

export const { handleSelectedServer } = serverSlice.actions;

export default serverSlice.reducer;
