// // ** React Imports
// import { useEffect } from 'react';
// import { NavLink } from 'react-router-dom';

// // ** Icons Imports
// import { Disc, X, Circle } from 'react-feather';

// // ** Service Constant Import
// import service from '@src/service/constant';

// // ** Config
// import themeConfig from '@configs/themeConfig';

// // ** Utils
// import { getUserData, getHomeRouteForLoggedInUser } from '@utils';
// import { useSelector } from 'react-redux';

// const VerticalMenuHeader = (props) => {
//     // ** Props
//     const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover, menuVisibility } = props;

//     const projectSettingData = useSelector((state) => state?.projectSettings?.projectSetting);

//     // ** Vars
//     const user = getUserData();

//     // ** Reset open group
//     useEffect(() => {
//         if (!menuHover && menuCollapsed) setGroupOpen([]);
//     }, [menuHover, menuCollapsed]);

//     // ** Menu toggler component
//     const Toggler = () => {
//         if (!menuCollapsed) {
//             return (
//                 <Disc
//                     size={20}
//                     data-tour="toggle-icon"
//                     className="text-primary toggle-icon d-none d-xl-block"
//                     onClick={() => setMenuCollapsed(true)}
//                 />
//             );
//         } else {
//             return (
//                 <Circle
//                     size={20}
//                     data-tour="toggle-icon"
//                     className="text-primary toggle-icon d-none d-xl-block"
//                     onClick={() => setMenuCollapsed(false)}
//                 />
//             );
//         }
//     };

//     return (
//         <div className="navbar-header">
//             <ul className="nav navbar-nav flex-row">
//                 <li className="nav-item" style={{ width: 'calc(100% - 26px)' }}>
//                     <NavLink
//                         to={user ? getHomeRouteForLoggedInUser(user.role) : '/'}
//                         className={`navbar-brand ${
//                             !menuCollapsed || (menuCollapsed && menuHover) || menuVisibility ? 'justify-content-center' : ''
//                         }`}
//                     >
//                         {menuCollapsed == false || (menuCollapsed == true && menuHover == true) || menuVisibility == true ? (
//                             projectSettingData?.logo !== undefined ||
//                             projectSettingData?.logo !== null ||
//                             projectSettingData?.logo !== '' ? (
//                                 <>
//                                     <span className="brand-logo">
//                                         <img
//                                             style={{
//                                                 width: Number(projectSettingData?.companyLogoWidth) || '40px',
//                                                 height: Number(projectSettingData?.companyLogoHeight) || '',
//                                                 marginTop: '-8px'
//                                             }}
//                                             src={projectSettingData?.logo}
//                                             alt="logo"
//                                         />
//                                     </span>
//                                     <h2 className="brand-text mb-0">{projectSettingData?.projectName}</h2>
//                                 </>
//                             ) : null
//                         ) : projectSettingData?.icon !== undefined ||
//                           projectSettingData?.icon !== null ||
//                           projectSettingData?.icon !== '' ? (
//                             <span className="brand-logo">
//                                 <img src={projectSettingData?.icon} height={40} width="auto" alt="Company icon" />
//                             </span>
//                         ) : null}
//                     </NavLink>
//                 </li>
//                 <li className="nav-item nav-toggle">
//                     <div className="nav-link modern-nav-toggle cursor-pointer">
//                         <Toggler />
//                         <X onClick={() => setMenuVisibility(false)} className="toggle-icon icon-x d-block d-xl-none" size={20} />
//                     </div>
//                 </li>
//             </ul>
//         </div>
//     );
// };

// export default VerticalMenuHeader;
// ** React Imports
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather';

// ** Service Constant Import
import service from '@src/service/constant';

// ** Config
import themeConfig from '@configs/themeConfig';

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from '@utils';
import { useSelector } from 'react-redux';

const VerticalMenuHeader = (props) => {
    // ** Props
    const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover, menuVisibility } = props;

    const projectSettingData = useSelector((state) => state?.projectSettings?.projectSetting);

    // ** Vars
    const user = getUserData();

    // ** Reset open group
    useEffect(() => {
        if (!menuHover && menuCollapsed) setGroupOpen([]);
    }, [menuHover, menuCollapsed]);

    // ** Menu toggler component
    const Toggler = () => {
        if (!menuCollapsed) {
            return (
                <Disc
                    size={20}
                    data-tour="toggle-icon"
                    className="text-primary toggle-icon d-none d-xl-block"
                    onClick={() => setMenuCollapsed(true)}
                />
            );
        } else {
            return (
                <Circle
                    size={20}
                    data-tour="toggle-icon"
                    className="text-primary toggle-icon d-none d-xl-block"
                    onClick={() => setMenuCollapsed(false)}
                />
            );
        }
    };

    return (
        <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
                <li className="nav-item" style={{ width: 'calc(100% - 26px)' }}>
                    <NavLink
                        to={user ? getHomeRouteForLoggedInUser(user.role) : '/'}
                        className={`navbar-brand ${
                            !menuCollapsed || (menuCollapsed && menuHover) || menuVisibility ? 'justify-content-center' : ''
                        }`}
                    >
                        {menuCollapsed === false || (menuCollapsed === true && menuHover === true) || menuVisibility === true ? (
                            projectSettingData?.logo ? (
                                <>
                                    <span className="brand-logo">
                                        <img
                                            style={{
                                                width: Number(projectSettingData?.companyLogoWidth) || '40px',
                                                height: Number(projectSettingData?.companyLogoHeight) || '',
                                                marginTop: '-8px'
                                            }}
                                            src={projectSettingData?.logo}
                                            alt="logo"
                                        />
                                    </span>
                                    {/* <h2 className="brand-text mb-0">{projectSettingData?.projectName}</h2> */}
                                </>
                            ) : null
                        ) : projectSettingData?.icon ? (
                            <span className="brand-logo">
                                <img src={projectSettingData?.icon} height={40} width="auto" alt="Company icon" />
                            </span>
                        ) : null}
                    </NavLink>
                </li>
                <li className="nav-item nav-toggle">
                    <div className="nav-link modern-nav-toggle cursor-pointer">
                        <Toggler />
                        <X onClick={() => setMenuVisibility(false)} className="toggle-icon icon-x d-block d-xl-none" size={20} />
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default VerticalMenuHeader;
