import React, { Fragment, useState, useEffect, useRef } from 'react';

// ** React Router Dom
import { useNavigate } from 'react-router-dom';

// ** Feather Icons
import { X } from 'react-feather';

// ** Reactstrap Imports
import { Card, Button, CardTitle, CardHeader, Label, CardBody, Row, Col, FormFeedback, CardFooter } from 'reactstrap';
import Select from 'react-select';
import { selectThemeColors } from '@utils';

// Dev Express Imports
import DataGrid from 'devextreme-react/data-grid';
import {
    SearchPanel,
    Scrolling,
    Column,
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    Button as DevBtn,
    Selection,
    GroupPanel,
    Export,
    FilterPanel,
    FilterBuilderPopup,
    LoadPanel
} from 'devextreme-react/data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import { ExcelExport } from '@utils';

// ** permission modules
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';

// import * as yup from 'yup';
import { getLanguageOptions } from '../../Language/store';
import { deleteTranslation, getTranslation, translationBulkUpdate } from '../store';
import TransactionModal from './Modal';
import BulkUpdateErrorModal from './BulkUpdateErrorModal';

// ** Loader Import
import showLoader from '@components/loader';

// ** Common Delete Modal
import DeleteModal from '@components/delete-modal';

const BulkUpdate = () => {
    // navigate object
    const navigate = useNavigate();
    const languageOptions = useSelector((state) => state?.language?.options);

    const {
        register,
        setValue,
        control,
        handleSubmit,
        setError,
        getValues,
        formState: { errors, isDirty }
    } = useForm({
        mode: 'onChange'
    });

    // dispatch object
    const dispatch = useDispatch();

    // ** DataGrid reference
    const dataGrid = useRef(null);

    // grid settings
    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 }
    };
    const pageSizes = [10, 25, 50, 100];

    // ** States
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [typeValue, setTypeValue] = useState(null);
    const [translationData, setTranslationData] = useState({});
    const [languageId, setLanguageId] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const [isDataSubmitted, setIsDataSubmitted] = useState(false);

    const handleTranslationData = async (data) => {
        const languageValue = getValues('languageId');
        if (!languageValue) {
            setError('languageId', {
                type: 'custom',
                message: 'Language is required.'
            });
        }
        const typeValue = getValues('type');

        if (!typeValue) {
            setError('type', {
                type: 'custom',
                message: 'Type is required.'
            });
        }

        if (languageValue && typeValue) {
            const data = { type: typeValue?.value, languageId: languageValue?.value };
            if (!isDirty) {
                setButtonStatus(false);
                return;
            }
            try {
                await dispatch(getTranslation(data))
                    .unwrap()
                    .then((res) => {
                        setDataSource(res);
                        setIsDataSubmitted(true);
                    })
                    .catch((err) => {
                        console.log('err', err);
                    });
            } catch (err) {
                console.log('err', err);
            }
        }
    };

    const typeOptions = [
        { value: '1', label: 'Web' },
        { value: '2', label: 'Mobile' }
    ];

    const typeOptionsObject = {
        1: { title: 'Web' },
        2: { title: 'Mobile' }
    };

    //** get All label options Data
    const onSubmit = async (data) => {
        const updatedRows = dataSource
            .filter((row) => row.value !== row.originalValue)
            .map((row) => ({
                translationId: row.id,
                value: row.value
            }));

        if (updatedRows.length === 0) {
            setButtonStatus(false);
            return;
        }

        if (data?.languageId?.value && data?.type?.value) {
            const groupData = {
                languageId: data?.languageId?.value,
                type: data?.type?.value,
                dataToUpdate: updatedRows
            };

            await dispatch(translationBulkUpdate(groupData))
                .unwrap()
                .then((res) => {
                    setButtonStatus(false);
                    showLoader(false);
                    navigate('/language-setting/translation');
                })
                .catch((err) => {
                    if (err?.response?.data?.fields) {
                        handleErrorModal(err?.response?.data?.fields);
                    }
                    setButtonStatus(false);
                    showLoader(false);
                });
        }
    };

    const getAllLanguageOptionData = async () => {
        await dispatch(getLanguageOptions());
    };

    // ** Function to handle Modal toggle
    const handleModal = async (data) => {
        if (data) {
            setTranslationData(data);
        } else {
            setTranslationData({});
        }
        setModal(!modal);
    };
    const handleDeleteModal = (row) => {
        deleteId ? setDeleteId() : setDeleteId(row);
        setDeleteModal(!deleteModal);
    };

    const deleteFunction = async () => {
        return await dispatch(deleteTranslation(deleteId))
            .unwrap()
            .then((res) => {
                handleDeleteModal();
            })
            .catch((err) => {});
    };

    const clearFunction = () => {
        setValue('type', null);
        setValue('languageId', null);
        setLanguageId(null);
        setTypeValue(null);
        setDataSource([]);
        setIsDataSubmitted(null);
    };

    const handleErrorModal = (errData) => {
        if (errData) {
            setErrorData(errData);
        } else {
            setErrorData([]);
        }
        setErrorModal(!errorModal);
    };

    const handleBulkUpdate = () => {
        navigate('/language-setting/translation');
    };

    useEffect(() => {
        getAllLanguageOptionData();
    }, []);

    return (
        <Fragment>
            <Card>
                <CardHeader className="flex-md-row flex-column align-md-items-center align-items-start border-bottom">
                    <CardTitle className="my-auto" tag="h4">
                        <div className="icon">
                            <Icon className="icon-color" icon="ion:language" width={26} height={26} style={{ paddingRight: '6px' }} />
                            Translation Bulk Update
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardBody style={{ paddingBottom: '10px' }}>
                    <Row className="mb-1 mt-1">
                        <Col md="2" sm="12" className="">
                            <Label>
                                Language<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="languageId"
                                control={control}
                                name="languageId"
                                render={({ field }) => (
                                    <Select
                                        id="languageId"
                                        classNamePrefix="select"
                                        theme={selectThemeColors}
                                        options={languageOptions}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        menuPortalTarget={document.body}
                                        className={classNames('react-select', {
                                            'is-invalid': errors.languageId?.message
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (e?.value) {
                                                setLanguageId(e?.value);
                                            } else {
                                                setLanguageId(null);
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.languageId && <FormFeedback>{errors.languageId.message}</FormFeedback>}
                        </Col>
                        <Col md="2" sm="12" className="">
                            <Label>
                                Type<span className="label-css">*</span>
                            </Label>
                            <Controller
                                id="type"
                                control={control}
                                name="type"
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="select"
                                        theme={selectThemeColors}
                                        options={typeOptions}
                                        {...register('type')}
                                        className={classNames('react-select', {
                                            'is-invalid':
                                                errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (e?.value) {
                                                setTypeValue(e?.value);
                                            } else {
                                                setTypeValue(null);
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors && errors?.type && (
                                <FormFeedback>
                                    {errors?.type?.message || errors?.type?.label?.message || errors?.type?.value?.message}
                                </FormFeedback>
                            )}
                        </Col>

                        <Col
                            md="4"
                            sm="12"
                            className="d-flex justify-content-start align-items-center gap-1 w-auto"
                            style={{ marginTop: '27px' }}
                        >
                            <Button
                                style={{ alignItems: 'center', height: '38px' }}
                                className="d-flex "
                                type="submit"
                                color="primary"
                                onClick={(e) => {
                                    handleTranslationData();
                                    e.preventDefault();
                                }}
                                disabled={!languageId || !typeValue}
                            >
                                <Icon icon={'ic:baseline-search'} style={{ fontSize: '20px' }} />
                                <span className=" justify-content-center">Get Translation</span>
                            </Button>

                            <Button
                                color="danger"
                                outline
                                size="sm"
                                className="btn-icon"
                                style={{ width: '40px' }}
                                onClick={(e) => {
                                    clearFunction();
                                    e.preventDefault();
                                }}
                            >
                                <X size={20} />
                            </Button>
                        </Col>
                    </Row>

                    <DataGrid
                        dataSource={dataSource}
                        showBorders={true}
                        showLoader={true}
                        autoExpandAll={true}
                        columnAutoWidth={true}
                        keyExpr="id"
                        onExporting={(e) => ExcelExport(e)}
                        ref={dataGrid}
                        editing={{
                            mode: 'batch',
                            allowUpdating: true
                        }}
                        onRowUpdating={(e) => {
                            const updatedRow = { ...e.oldData, ...e.newData };

                            const updatedDataSource = dataSource.map((item) => (item.id === updatedRow.id ? updatedRow : item));

                            setDataSource(updatedDataSource);

                            e.cancel = true;
                        }}
                    >
                        <LoadPanel enabled={true} />
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} />
                        <HeaderFilter visible={true} />
                        <Scrolling mode="standard" scrollByContent />
                        <FilterPanel visible={true} />
                        <FilterBuilderPopup position={filterBuilderPopupPosition} />
                        <Selection mode="multiple" />
                        <GroupPanel visible={true} />
                        <Export enabled={false} allowExportSelectedData={false} />

                        <Column dataField="value" caption="Value" dataType="string" />
                        <Column dataField="Label.name" caption="Label" allowEditing={false} />

                        <Paging defaultPageSize={10} />
                        <Pager
                            visible={true}
                            allowedPageSizes={pageSizes}
                            showPageSizeSelector={true}
                            displayMode="full"
                            showInfo={true}
                            showNavigationButtons={true}
                        />
                    </DataGrid>
                    <CardFooter className="py-1">
                        <div className="d-flex justify-content-start">
                            {isDataSubmitted && (
                                <div className="">
                                    <Button
                                        disabled={buttonStatus}
                                        color="primary"
                                        type="submit"
                                        className="me-1"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            )}
                            <div className="">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        handleBulkUpdate();
                                    }}
                                    outline
                                    className="d-flex justify-content-center"
                                >
                                    <div className="justify-content-center" style={{ marginRight: '5px' }}>
                                        <Icon icon="bx:arrow-back" width={14} className="icon-size" />
                                    </div>
                                    Back
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </CardBody>
            </Card>
            <TransactionModal open={modal} handleModal={handleModal} translationData={translationData} />
            <DeleteModal open={deleteModal} title="Delete Translation " handleModal={handleDeleteModal} deleteFunction={deleteFunction} />
            <BulkUpdateErrorModal open={errorModal} errorData={errorData} handleErrorModal={handleErrorModal} />
        </Fragment>
    );
};

export default BulkUpdate;
