import { Outlet } from 'react-router-dom';

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout';

// ** Menu Items Array
import navigation from '@src/navigation/vertical';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavigationMenu } from '../views/Settings/MenuOrder/store';
import { Icon } from '@iconify/react';

const VerticalLayout = (props) => {
    // const [menuData, setMenuData] = useState([])
    const dispatch = useDispatch();
    const menuData = useSelector((state) => state.menuOrder.navigationMenu);

    // ** For ServerSide navigation
    // useEffect(() => {
    //   axios.get(URL).then(response => setMenuData(response.data))
    // }, [])

    const mapMenuIcons = (menuItems) => {
        return menuItems.map((item) => {
            const newItem = {
                ...item,
                icon: item?.icon ? <Icon icon={item?.icon} width={20} height={20} /> : null
            };

            if (item.children) {
                newItem.children = mapMenuIcons(item.children);
            }

            return newItem;
        });
    };

    const updatedMenuData = menuData ? mapMenuIcons(menuData) : [];

    if (process.env.NODE_ENV === 'development') {
        let moduleObj = {
            icon: <Icon icon="codicon:file-submodule" width={20} height={20} />,
            id: '1',
            level: 15,
            navLink: '/module',
            parentId: null,
            title: 'Module'
        };
        updatedMenuData.push(moduleObj);
    }

    const getAllMenus = async () => {
        await dispatch(getNavigationMenu());
    };

    useEffect(() => {
        getAllMenus();
    }, []);

    return (
        <Layout menuData={updatedMenuData} {...props}>
            <Outlet />
        </Layout>
    );
};

export default VerticalLayout;
