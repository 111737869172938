// ** Icons Import
import { Home, Circle } from 'react-feather';
import { Icon } from '@iconify/react';

export default [
    {
        id: 'Image Template',
        title: 'Image Template',
        icon: <Icon icon="material-symbols:image-outline" width="30" height="30" />,
        navLink: '/image-template'
    }
];
