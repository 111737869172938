import { lazy } from 'react';
import modules from '@src/service/modules';

const Configuration = lazy(() => import('../../views/Settings/Configuration/index'));
const Emailconfig = lazy(() => import('../../views/Settings/Configuration/components/EmailConfig'));
const ProjectSetting = lazy(() => import('../../views/Settings/ProjectSetting/index'));
const MenuOrder = lazy(() => import('../../views/Settings/MenuOrder/index'));
const TemplateGallery = lazy(() => import('../../views/Settings/TemplateGallery/index'));
const EmailTemplateForm = lazy(() => import('../../views/Settings/TemplateGallery/components/Form'));
const ManageModule = lazy(() => import('../../views/Setup/ModuleManage/index'));

const SettingsRoutes = [
    {
        path: '/settings/configuration',
        element: <Configuration />
        // dbId: modules.settings_configuration
    },
    {
        path: '/settings/configuration/email-config',
        element: <Emailconfig />
    },
    {
        path: '/settings/project-setting',
        element: <ProjectSetting />
        // dbId: modules.settings_project
    },
    {
        path: '/settings/menu-order',
        element: <MenuOrder />
        // dbId: modules.settings_menu_order
    },
    {
        path: '/settings/email-templates',
        element: <TemplateGallery />,
        meta: {
            appLayout: true,
            className: 'email-application'
        }
        // dbId: modules.settings_email_templates
    },
    {
        path: '/settings/email-templates/add',
        element: <EmailTemplateForm />
        // dbId: modules.settings_add_template
    },
    {
        path: '/settings/email-templates/:folder',
        element: <TemplateGallery />,
        meta: {
            appLayout: true,
            className: 'email-application'
        }
    },
    {
        path: '/settings/email-templates/add',
        element: <EmailTemplateForm />
        // dbId: modules.settings_add_template
    },
    {
        path: '/settings/email-templates/edit/:id',
        element: <EmailTemplateForm />
    }
];

if (process.env.NODE_ENV === 'development') {
    SettingsRoutes.push({
        path: '/module',
        element: <ManageModule />
        // dbId: modules.setup_manage_module
    });
}

export default SettingsRoutes;
