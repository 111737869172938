import { lazy } from 'react';
import modules from '@src/service/modules';
import { element } from 'prop-types';
import BulkImportModal from '../../views/LanguageSetting/Label/components/BulkImport';
import BulkImport from '../../views/LanguageSetting/Translation/components/BulkImport';
import BulkUpdate from '../../views/LanguageSetting/Translation/components/BulkUpdate';
import BulkUpdateLabel from '../../views/LanguageSetting/Label/components/BulkUpdate';

const Language = lazy(() => import('../../views/LanguageSetting/Language/index'));
const Label = lazy(() => import('../../views/LanguageSetting/Label/index'));
const Translation = lazy(() => import('../../views/LanguageSetting/Translation/index'));

const SettingsRoutes = [
    {
        path: '/language-setting/language',
        element: <Language />
        // dbId: modules.settings_configuration
    },
    {
        path: '/language-setting/label',
        element: <Label />
    },
    {
        path: '/language-setting/label/bulk-import',
        element: <BulkImportModal />
    },
    {
        path: '/language-setting/label/bulk-update',
        element: <BulkUpdateLabel />
    },
    {
        path: '/language-setting/translation',
        element: <Translation />
        // dbId: modules.settings_project
    },
    {
        path: '/language-setting/translation/bulk-import',
        element: <BulkImport />
        // dbId: modules.settings_project
    },
    {
        path: '/language-setting/translation/bulk-update',
        element: <BulkUpdate />
        // dbId: modules.settings_project
    }
];

export default SettingsRoutes;
